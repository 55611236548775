import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";

const Contact = () => {
    const [activeTab, setActiveTab] = useState("apply_for_job");

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phone: "",
            contactType: "apply_for_job",
            resume: "",
            description: "",
        },
        onSubmit: async (values) => {
            try {
                const formData = new FormData();
                formData.append("name", values.name);
                formData.append("contact[email]", values.email);
                formData.append("contact[phone_number]", values.phone);
                formData.append("contact[contact_type]", values.contactType);
                formData.append("contact[file]", values.resume);
                formData.append("contact[description]", values.description);

                const config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: "https://3.110.214.74/api/contacts",
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    data: formData,
                };

                const response = await axios.request(config);
                console.log(JSON.stringify(response.data));
                toast.success("Form Submittes Successfully");
            } catch (error) {
                console.log(error);
                toast.error(error);
            }
        },
    });
    const handleTabClick = (contactType) => {
        setActiveTab(contactType);
        formik.setFieldValue("contactType", contactType);
    };
    return (
        <>
            {/* breadcrumb start */}
            <section
                className="breadcrumb bg_img d-flex align-items-center dark-2"
                data-overlay="dark-2"
                data-opacity={7}
            >
                <div className="container p-0">
                    <div className="breadcrumb__content text-center">
                        <h2>Contact</h2>
                        <ul className="bread-crumb clearfix ul_li_center">
                            <li className="breadcrumb-item">
                                <a href="/">Home</a>
                            </li>
                            <li className="breadcrumb-item">Contact</li>
                        </ul>
                    </div>
                </div>
            </section>
            {/* breadcrumb end */}
            {/* contact start */}
            <section className="contact pt-120 pb-120">
                <div className="container p-0">
                    <div className="row mt-none-30">
                        <div className="col-lg-4 mt-30">
                            <div className="contact__widget-inner">
                                <h3>Contact Information</h3>
                                <p>
                                    We’ve grown up with the internet revolution, and we know how
                                    to deliver on its
                                </p>
                                <ul className="contact__widget-info list-unstyled">
                                    <li>
                                        <div className="icon">
                                            <i className="invite-text-gr-color fas fa-map-marker-alt" />
                                        </div>
                                        <div className="content">
                                            <h3>Office Address</h3>
                                            <p>
                                                407 , Satguru Parinay, Scheme 54, opposite C21 Mall,
                                                PU4, AB Road, Indore,Madhya Pradesh 452011
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="invite-text-gr-color fas fa-phone-alt" />
                                        </div>
                                        <div className="content">
                                            <h3>Phone Number</h3>
                                            <p>+91- 96447 80306</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="invite-text-gr-color fas fa-envelope-open-text" />
                                        </div>
                                        <div className="content">
                                            <h3>Mail Address</h3>
                                            <p>business@justmindwork.com | sales@justmindwork.com</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 mt-30">
                            <div className="contact__form-inner">
                                <h3>Keep In Touch</h3>
                                <p>
                                    With over a decade of experience, we’ve established ourselves
                                    as one of the pioneering agencies in the region. Our small,
                                    flexible, agile and design-led structures and processes.
                                </p>
                                <div className="box-button-navigate">
                                    <button
                                        onClick={() => handleTabClick("apply_for_job")}
                                        className={activeTab === "apply_for_job" ? "active" : ""}
                                    >
                                        Apply for Job
                                    </button>
                                    <button
                                        onClick={() => handleTabClick("create_website")}
                                        className={activeTab === "create_website" ? "active" : ""}
                                    >
                                        Share your requirement
                                    </button>
                                </div>

                                {activeTab === "apply_for_job" && (
                                    <>
                                        <form onSubmit={formik.handleSubmit}>
                                            <label>Name:</label>
                                            <input type="text" {...formik.getFieldProps("name")} />

                                            <label>Email:</label>
                                            <input type="text" {...formik.getFieldProps("email")} />

                                            <label>Phone:</label>
                                            <input type="text" {...formik.getFieldProps("phone")} />
                                            <label>Resume:</label>
                                            <br />
                                            <input
                                                type="file"
                                                onChange={(event) =>
                                                    formik.setFieldValue(
                                                        "resume",
                                                        event.currentTarget.files[0]
                                                    )
                                                }
                                            />
                                            <br />

                                            <div className="text-center">
                                                <button type="submit" className="thm-btn thm-btn__gradient mt-30 ">
                                                    <span className="btn-anim-wrap">
                                                        <span className="button-text">Submit Response</span>
                                                        <span className="button-text">Submit Response</span>
                                                    </span>
                                                    <i className="flaticon-top-right" />
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                )}

                                {activeTab === "create_website" && (
                                    <>
                                        <form onSubmit={formik.handleSubmit}>

                                            <label>Name:</label>
                                            <input type="text" {...formik.getFieldProps("name")} />

                                            <label>Email:</label>
                                            <input type="text" {...formik.getFieldProps("email")} />

                                            <label>Phone:</label>
                                            <input type="text" {...formik.getFieldProps("phone")} />
                                            <label>Description:</label>
                                            <textarea {...formik.getFieldProps("description")} />

                                            <div className="text-center">
                                                <button type="submit" className="thm-btn thm-btn__gradient mt-30 ">
                                                    <span className="btn-anim-wrap">
                                                        <span className="button-text">Submit Reqiurement</span>
                                                        <span className="button-text">Submit Reqiurement</span>
                                                    </span>
                                                    <i className="flaticon-top-right" />
                                                </button>
                                            </div>
                                        </form>

                                    </>
                                )}
                                <div>
                                    {/* <form onSubmit={formik.handleSubmit}>
                    <label>Name:</label>
                    <input type="text" {...formik.getFieldProps("name")} />

                    <label>Email:</label>
                    <input type="text" {...formik.getFieldProps("email")} />

                    <label>Phone:</label>
                    <input type="text" {...formik.getFieldProps("phone")} />

                    <label>Contact Type:</label>
                    <select {...formik.getFieldProps("contactType")}>
                      <option value="apply_for_job">Apply for Job</option>
                      <option value="create_website">
                        Share your requirement
                      </option>
                    </select>
                    {formik.values.contactType === "apply_for_job" ? (
                      <>
                        <label>Resume:</label>
                        <br />
                        <input
                          type="file"
                          onChange={(event) =>
                            formik.setFieldValue(
                              "resume",
                              event.currentTarget.files[0]
                            )
                          }
                        />
                        <br />
                        <br />
                      </>
                    ) : null}

                    {formik.values.contactType === "create_website" ? (
                      <>
                        <label>Description:</label>
                        <textarea {...formik.getFieldProps("description")} />
                      </>
                    ) : null}


                    <button type="submit">Submit</button>
                  </form> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* contact end */}
            {/* google map start */}
            <section className="google-map">
                <div className="google-map__inner">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14602.254272231177!2d90.3654215!3d23.7985508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1592852423971!5m2!1sen!2sbd"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex={0}
                    />
                </div>
            </section>
            {/* google map end */}
        </>
    );
};

export default Contact;
